import React, { useState, useContext } from 'react';
import { Title } from '../../shared/Typography/Title';
import { useStaticQuery, graphql } from 'gatsby';
import { useFormik } from 'formik';
import { Input, InputControl, InputError } from '../../shared/Input/Input';
import { Label } from '../../shared/Label/Label';
import { Container } from '../../shared/Container/Container';
import { ContactFormWrapperInner } from './contact-form.styles';
import ButtonNavigation from 'src/components/shared/ButtonNavigation/ButtonNavigation';
import { LockIcon, PrivacyPolicy } from '../../FlowContact/style';
import { LINKS } from 'src/constants/links';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { FORM_URLS } from '../../../constants/pardot';
import { PROPERTY_RELATIONSHIP_ANSWERS } from '../GetFreeOffer.container';
import { ButtonPrimary } from '../../shared/Buttons/Buttons';
import { ContactFormWrap } from './select-type-of-contact.styles';
import GetFreeOfferForm from './GetFreeOfferForm';
import Context from '../../../context/Context';
import { getFormattedPhoneNumber } from '../../../utils/form';
import { ShoppingCartContext } from 'src/components/ShoppingCart/ShoppingCart.context.tsx';
import LOCAL_KEYS from '../../../constants/localStorage';
import store from 'store2';
import useOfferTypes from 'src/hooks/useOfferTypes';

function ContactForm({
  onSubmitFormSuccess,
  propertyRelationship,
  propertyCondition,
  transactionDuration,
  address,
  specificRelationship,
  zip,
  city,
  state,
  street,
}) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    validateOnMount: true,
    validate: () => {
      const errors = {};
      if (!formik.values.firstName) {
        errors.firstName = 'Please enter your first name';
      } else if (formik.values.firstName.length < 2) {
        errors.firstName = 'Please enter your first name';
      }

      if (!formik.values.lastName) {
        errors.lastName = 'Please enter your last name';
      } else if (formik.values.lastName.length < 2) {
        errors.lastName = 'Please enter your last name';
      }

      if (!formik.values.email) {
        errors.email = 'Please enter your email';
      } else if (formik.values.email.length < 2) {
        errors.email = 'Please enter your email';
      }

      if (!formik.values.email) {
        errors.email = 'Please enter your email';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formik.values.email)
      ) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
  });

  const { getOfferTypeNameById, isValidOfferType } = useOfferTypes();

  const data = useStaticQuery(graphql`
    query {
      addonsList: contentfulAddonList {
        addon {
          title
          contentful_id
        }
      }
      offerTypes: contentfulContentContainer(slug: { eq: "all-offer-types" }) {
        content {
          ... on ContentfulPageTemplate {
            title
            contentful_id
          }
        }
      }
    }
  `);

  const { addons, offerTypes } = useContext(ShoppingCartContext);

  const isCheckout = store.get(LOCAL_KEYS.IS_CHECKOUT) === true;

  const offerTypeId = store.get(LOCAL_KEYS.OFFER_TYPE_ID);

  function getAddons() {
    if (isCheckout) {
      return data.addonsList.addon.filter((addon) => {
        return addons.includes(addon.contentful_id);
      });
    } else {
      return [];
    }
  }

  function getOfferType() {
    let offerType = '';

    if (isCheckout && offerTypes.length > 0) {
      const isValid = isValidOfferType(offerTypes[0]);
      if (isValid) {
        offerType = getOfferTypeNameById(offerTypes[0]);
      }
    } else {
      const isValid = isValidOfferType(offerTypeId);
      if (isValid) {
        offerType = getOfferTypeNameById(offerTypeId);
      }
    }

    return offerType;
  }

  const isFirstnameError = formik.errors.firstName && formik.touched.firstName;
  const isLastnameError = formik.errors.lastName && formik.touched.lastName;
  const isEmailError = formik.errors.email && formik.touched.email;
  return (
    <ContactFormWrap>
      <ContactFormWrapperInner>
        <Container>
          <Title size="medium"> Where can we contact you with your offer options?</Title>
          <div className="contact-form-inputs-wrap">
            <InputControl>
              <Label isRequired={true}>First name</Label>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                isError={isFirstnameError}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                onFocus={() => formik.setFieldError('firstName', '')}
                onBlur={formik.handleBlur}
              />
              <InputError>
                {isFirstnameError && formik.errors.firstName}
              </InputError>
            </InputControl>
            <InputControl>
              <Label isRequired={true}>Last name</Label>
              <Input
                id="lastName"
                name="lastName"
                isError={isLastnameError}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                onFocus={() => formik.setFieldError('lastName', '')}
                onBlur={formik.handleBlur}
              />
              <InputError>
                {isLastnameError && formik.errors.lastName}
              </InputError>
            </InputControl>
            <InputControl>
              <Label isRequired={true}>Email</Label>
              <Input
                isError={isEmailError}
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onFocus={() => formik.setFieldError('email', '')}
                onBlur={formik.handleBlur}
              />
              {isEmailError && <InputError>{formik.errors.email}</InputError>}
            </InputControl>
            <InputControl>
              <Label>Phone</Label>
              <Input
                id="phone"
                name="phone"
                type="tel"
                onChange={(event) => {
                  const phoneNumber = getFormattedPhoneNumber(
                    event.target.value
                  );
                  formik.setFieldValue('phone', phoneNumber);
                }}
                value={formik.values.phone}
              />
            </InputControl>
            <div
              className="contact-form-wrap"
              onSubmit={() => {
                onSubmitFormSuccess();
              }}
            ></div>
          </div>
          <GetFreeOfferForm
            firstName={formik.values.firstName}
            lastName={formik.values.lastName}
            email={formik.values.email}
            phone={formik.values.phone}
            street={street}
            city={city}
            state={state}
            address={address}
            addOn1={getAddons()?.length >= 1 ? getAddons()[0].title : undefined}
            addOn2={getAddons()?.length >= 2 ? getAddons()[1].title : undefined}
            addOn3={getAddons()?.length >= 3 ? getAddons()[2].title : undefined}
            primaryOffer={getOfferType()}
            propertyCondition={propertyCondition}
            transactionDuration={transactionDuration}
            zip={zip}
            propertyRelationship={propertyRelationship}
            specificRelationship={specificRelationship}
          >
            <ButtonPrimary
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Get free custom offer
            </ButtonPrimary>
            <div className="type-of-contact-button-area">
              <PrivacyPolicy>
                <LockIcon />
                Your contact information will be protected by our{' '}
                <a href={LINKS.PRIVACY.url}>Privacy Policy</a>.
              </PrivacyPolicy>
            </div>
          </GetFreeOfferForm>
        </Container>
      </ContactFormWrapperInner>
    </ContactFormWrap>
  );
}

export default ContactForm;
